// Buttons

button {
  margin: 2px !important;
}

.button {
  padding: 0.5em 1em;
  border-radius: 4px;
  cursor: pointer;
}
