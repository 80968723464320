// Dashboard Page
#Dashboard {
  display: flex;
  flex-direction: row;
  .sidebar {
    width: 20rem;
    padding: 1rem;
    min-height: calc(100vh - 14rem);
  }
  .container {
    width: calc(100vw - 20rem);
  }
}
