// abstracts/_variables.scss

:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #ffffff;
  --background-color-layout: #969696;
  --text-color: #333333;
  --border-color: #e0e0e0;

  --font-family-base: 'Helvetica, Arial, sans-serif';
  --font-size-base: 16px;
}
