@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

// Typography
p {
  font-size: calc(0.4vw + 0.6rem) !important;
  font-weight: 200 !important;
  line-height: calc(0.4vw + 0.85rem) !important;
  margin-bottom: 0 !important;

  color: var(--text-color);
}

h1 {
  font-size: calc(0.6vw + 1rem) !important;
  font-weight: 800 !important;
  margin-bottom: 0 !important;

  color: var(--text-color);
}

h2 {
  font-size: calc(0.6vw + 0.9rem) !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important;

  color: var(--text-color);
}

h3 {
  font-size: calc(0.6vw + 0.8rem) !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important;

  color: var(--text-color);
}

h4 {
  font-size: calc(0.6vw + 0.7rem) !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important;

  color: var(--text-color);
}

i {
  font-size: 1.5rem;
  filter: drop-shadow(0 0 0.05rem #072d50);
  color: var(--text-color-secondary);
  &:hover {
    filter: brightness(150%);
  }
}

.btn {
  --bs-btn-color: var(--text-color-secondary) !important;
}

a {
  font-size: calc(0.6vw + 0.5rem) !important;
  font-weight: 600 !important;
  line-height: 1.15 !important;
  margin-bottom: 0 !important;
  color: var(--text-link-color);
  * {
    color: var(--text-link-color);
  }
}

.text-hover-highlighted:hover {
  filter: brightness(125%);
}