/* dark-theme.css */

#root.dark-mode {
  --primary-color: #2980b9;
  --secondary-color: #27ae60;
  --background-color: #181818;
  --background-color-secondary: #212020;
  --background-color-layout: #464646;
  --text-color: #f1f1f1;
  --text-color-secondary: #c4c4c4;
  --text-link-color: #697ec9;
  --border-color: #444444;

  --font-family-base: 'Helvetica, Arial, sans-serif';
  --font-size-base: 16px;
}
