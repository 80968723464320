/* light-theme.css */

#root.light-mode {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #ffffff;
  --background-color-secondary: #e8dbdb;
  --background-color-layout: #969696;
  --text-color: #333333;
  --text-color-secondary: #181818;
  --text-link-color: #0c2060;
  --border-color: #e0e0e0;

  --font-family-base: 'Helvetica, Arial, sans-serif';
  --font-size-base: 16px;
}
