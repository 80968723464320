#root {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Inter', Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  * {
    z-index: 1;
    transition: 0.2s;
  }
}

#rootApp {
  min-height: calc((100vh - 6.25rem) - 6.25rem);
}