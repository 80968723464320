/* dark-theme.css */

.table *{
  --bs-table-color: var(--text-color) !important;
}

.table-primary {
  --bs-table-bg: var(--background-color-secondary) !important;
}

.table-info {
  --bs-table-bg: var(--secondary-color) !important;
}

.tableActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}