// Modals
.modal {
  display: flex !important;
  justify-content: center;
  .modal-dialog,
  .modal-content{
    width: 50vw !important;
    min-width: 50vw !important;
    max-width: 50vw !important;
    margin: 0 0 0 0 !important;
  }
}
