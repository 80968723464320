/* src/styles/ToastContainer.css */
#toastContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  display: flex;
  flex-direction: column-reverse;
  gap: 6px;

  .toast {
    background-color: var(--background-color-secondary);
    color: var(--text-color-secondary);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &.toast-success {
      border-left: 4px solid green;
    }

    &.toast-error {
      border-left: 4px solid red;
    }

    &.toast-info {
      border-left: 4px solid blue;
    }

    .toast-header {
      background: var(--background-color-tertiary);
      color: var(--text-color);
      width: 100%;
      img {
        width: 2rem;
        height: 2rem;
      }
    }

    .toast-footer {
      background: var(--background-color-tertiary);
      color: var(--text-color) 0.5;
      font-size: 12px;
      width: 100%;
      border-top: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
      border-bottom-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
      border-bottom-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2px;
    }

    .toast-body {
      width: 100%;
      text-align: left;
    }
  }
}

