@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Override the variable causing the issue
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2px !default;

// Import the rest of Bootstrap
@import "~bootstrap/scss/bootstrap";

@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';
