Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 1rem;
  height: 6.25rem;

  background: var(--background-color-layout);

  .leftContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .logoContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      gap: 0.2rem;
      color: var(--text-color);
      img {
        width: 2rem;
      }
    }

    .toggleTheme {
      border-radius: 2rem;
      padding: 0.2rem;
      background: black;
      margin: 0;
      border: 0;
      height: 2rem;
      width: 4rem;
      cursor: pointer;
      position: relative;

      img {
        height: 1.4rem;
        width: 1.4rem;
        border-radius: 100%;
        position: absolute;
        top: 0.3rem; // vertically center the image
        left: 0.2rem; // starting position in light mode
        transition: transform 0.3s ease;

        pointer-events: none;

        // Move to the right in dark mode
        .dark-mode & {
          transform: translateX(2rem); // move the image to the right
        }
      }
    }
  }
  a {
    padding: 0.2rem;

    button {
      font-weight: 600 !important;
      background: transparent;
      border: none;
      &:hover {
        filter: brightness(200%);
      }
    }
  }
}