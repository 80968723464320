// Container
.container {
  padding: 1em;
}

.floatingContainer {
  max-width: 800px;
  margin: 16px auto;
  background-color: var(--background-color-secondary);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}